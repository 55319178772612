import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'numberDecimal'})
export class TransformNumberDecimalPipe implements PipeTransform {
  transform(value: number, unit: string = '', numberDecimal: number = 2) {
      return this.parseNumber(value, numberDecimal, unit);
  }
  parseNumber(number, numberDecimal?, unit?) {
    if (number) {
      let numberString = number + '';
      let negative = false;
      if (numberString[0] === '-') {
         negative = true;
         numberString = numberString.substring(1, number.length);
      }
      const array = numberString.split('.');
      if (numberDecimal) {
      }
      let decimalNumber = array[1] ? numberDecimal ? this.decimalAdjust( parseFloat('0.' + array[1]) , numberDecimal) + '' : '0'  : '0';
      let integerNumber = (parseInt(array[0])) + ''; 
      let index = 0, str = '';
   
      // end
      for (let i= integerNumber.length - 1; i >= 0; i--) {
        index++;
        str= integerNumber.charAt(i) + str;
        if (index % 3 === 0) {
          str = ',' + str;
          index = 0;
        }
      }
      if (str.charAt(0) === ',') {
        str = str.substring(1, str.length);
      }
      negative && (str = '-' + str)
      decimalNumber && (str = str + decimalNumber.substring(1))
      return str + (unit || '');
    } 
    else if (number === 0 || number === '0') {
      return '0' + (unit || '');
    } 
    else {
      return '-/-';
    }
  }
  decimalAdjust(value, exp = 2) {
    return !this.isNull(value) ? parseFloat(value.toFixed(exp)) : null;
  }
  isNull(amount?) {
    return amount === null || amount === undefined;
  }
}