.legend-container {
  min-width: 250px;
}

.chart-data {
  // width: 250px;
  width: 100%;
  gap: 20px;
}

.horizontal-chart {
  .chart-data {
    .pie-chart {
      width: 150px;
    }

    .legend-container {
      min-width: 200px !important;
    }
  }
}

.notice__table {
  font-size: 14px;
  tbody {
    tr {
      td {
        padding: 5px;
        border: none !important;
        background: transparent !important;

        strong {
          font-size: 12px;
          font-family: 'OpenSans7';
        }
      }
    }
  }
}

.chart-legend-status {
  display: grid;
  grid-template-columns: 20px auto;
  align-items: center;

  span {
    font-size: 14px;
  }
}
