export class NumberUtil {
  static accomplish(value, numberDecimal = 2) {
    const number = value * Math.pow(10, numberDecimal);
    return Math.round(number) / Math.pow(10, numberDecimal);
  }
  static decimalAdjust(value, exp = 2, type?) {
    return !this.isNull(value) ? parseFloat(value.toFixed(exp)) : null;
  }
  static revertMoneytoNumber(number, returnNull?) {
    number = number ? number + '' : '';
    let isNegative = false;
    if (number[0] === '-') {
      isNegative = true;
      number = number.substring(1);
    }
    if (number.length > 0) {
      let array = number.split(',');
      number='';
      array.forEach((item) => {
        number+=item
      })
      return parseFloat(number) * (isNegative ? -1 : 1);
    } else if (number !== 0) {
      return !returnNull ? 0 : null;
    } else if (number === '' && isNegative) {
      return null;
    } else {
      return 0;
    }
  }
  static removeCommaForString(number) {
    let numberString = number ? number + '' : '';
    return numberString.replaceAll(',' ,'');
  }
  static numberToMoney(number: any) {
    let isNegative: boolean = false;
    let isDemacial: boolean = false;
    if (number < 0) {
      isNegative = true;
      number = number*-1;
    }
    let numberText = number +'';
    const splitDemacial = numberText.split('.');
    let numberPositive = splitDemacial[0] || '';
    let numberDecimal = splitDemacial[1] || '';
    if (splitDemacial.length === 2) {
      isDemacial = true;
    }
    let text = '';
    let position = 0;
    for (let i=numberPositive.length -1; i >= 0; i--) {
      if (position === 3) {
        text = ',' + text; 
        position = 0;
      }
      text = numberPositive.charAt(i) + text;
      position++;
    }
    if (isNegative) {
      text = '-' + text;
    }
    if (isDemacial) {
      text += '.' + numberDecimal;
    }
    return text;
  }
  static isZero(amount?) {
    return amount !== null && amount !== undefined && amount === 0;
  }
  static isNull(amount?) {
    return amount === null || amount === undefined;
  }
}
